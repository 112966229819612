<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Contract.Create.Title") }}
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <h6 class="title is-6">{{ $t("Interface.Contract.Company.Title") }}</h6>
            <div class="columns is-multiline">
                <div class="column is-full">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        mode="aggressive"
                        tag="div"
                        rules="required"
                        class="block"
                        name="Type"
                    >
                        <b-radio v-model="Company.Type" native-value="owner">
                            {{ $t("Interface.Contract.Company.TypeOwner") }}
                        </b-radio>
                        <b-radio v-model="Company.Type" native-value="agent">
                            {{ $t("Interface.Contract.Company.TypeAgent") }}
                        </b-radio>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    ref="INN"
                    tag="div"
                    rules="required|digits:10"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.INN')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.INN')"
                        custom-class="has-text-weight-semibold"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.Inn"
                            type="text"
                            data-cy="INN"
                            maxlength="10"
                            @blur="getByINN()"
                        />
                    </b-field>
                </ValidationProvider>

                <div class="column is-half">
                    <b-field :label="$t('Interface.Contract.Company.KPP')" expanded>
                        <b-input
                            v-model="Company.Kpp"
                            type="text"
                            data-cy="KPP"
                        />
                    </b-field>
                </div>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.OGRN')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.OGRN')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.Ogrn"
                            type="text"
                            data-cy="OGRN"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.Address')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.Address')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.Address"
                            type="text"
                            data-cy="Address"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.Name')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.Name')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.ShortName"
                            type="text"
                            data-cy="Name"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.FullName')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.FullName')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.FullName"
                            type="text"
                            data-cy="FullName"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.Registered')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.Registered')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-datepicker
                            v-model="Company.Registered"
                            trap-focus
                            data-cy="Registered"
                            :class="{ 'is-danger': errors[0] }"
                            required
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.ManagerName')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.ManagerName')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.ManagerName"
                            type="text"
                            data-cy="ManagerName"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.ManagerPosition')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.ManagerPosition')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.ManagerPosition"
                            type="text"
                            data-cy="ManagerPosition"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Company.ManagerCredentials')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Company.ManagerCredentials')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.ManagerCredentials"
                            type="text"
                            data-cy="ManagerCredentials"
                        />
                    </b-field>
                </ValidationProvider>
            </div>
            <h6 class="title is-6">{{ $t("Interface.Contract.Bank.Title") }}</h6>
            <div class="columns is-multiline">
                <ValidationProvider
                    v-slot="{ valid, errors }"
                    ref="BIC"
                    tag="div"
                    rules="required|digits:9"
                    class="column is-half"
                    :name="$t('Interface.Contract.Bank.BIC')"
                    vid="bic"
                >
                    <b-field
                        :label="$t('Interface.Contract.Bank.BIC')"
                        custom-class="has-text-weight-semibold"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.Bic"
                            type="text"
                            data-cy="BIC"
                            maxlength="9"
                            @blur="getByBIC()"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Bank.Name')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Bank.Name')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.BankName"
                            type="text"
                            data-cy="BankName"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Bank.City')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Bank.City')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.BankCity"
                            type="text"
                            data-cy="BankCity"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Contract.Bank.CorrespondentAccount')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Bank.CorrespondentAccount')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.CorrespondentBankAccount"
                            type="text"
                            data-cy="CorrespondentAccount"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|digits:20"
                    class="column is-half"
                    :name="$t('Interface.Contract.Bank.RS')"
                >
                    <b-field
                        :label="$t('Interface.Contract.Bank.RS')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input
                            v-model="Company.BankAccount"
                            type="text"
                            data-cy="BankAccount"
                            maxlength="20"
                        />
                    </b-field>
                </ValidationProvider>
            </div>
            <button
                type="submit"
                class="button is-theme is-fullwidth"
                :disabled="invalid"
                @click.prevent="handleSubmit(createCompany)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    BIC_REQUEST,
    INN_REQUEST,
    COMPANY_CREATE_REQUEST
} from "@/store/actions/contract"

export default {
    name: "ContractCreateRussia",
    mixins: [LoadingState],
    data() {
        return {
            Company: {
                Country: "ru",
                FullName: null,
                ShortName: null,
                Registered: null,
                Ogrn: null,
                Inn: null,
                Kpp: null,
                Address: null,
                ManagerPosition: null,
                ManagerName: null,
                ManagerCredentials: "Устав",
                BankName: null,
                BankCity: null,
                Bic: null,
                CorrespondentBankAccount: null,
                BankAccount: null,
                Type: null
            }
        }
    },
    mounted() {
        if (this.$store.getters.IS_COMPANY_CREATED) this.$router.push({ name: "Contract" })
    },
    methods: {
        createCompany() {
            this.switchLoading()
            const Contract = JSON.parse(JSON.stringify(this.Company))

            // Конвертация даты регистрации из локального в формат UTC со смещением по часовому поясу
            const RegisteredDate = new Date(Contract.Registered)
            const UserTimezoneOffset = RegisteredDate.getTimezoneOffset() * 60000
            Contract.Registered = new Date(RegisteredDate.getTime() - UserTimezoneOffset).toISOString()

            this.$store.dispatch(COMPANY_CREATE_REQUEST, Contract)
                .then(() => {
                    const routeData = this.$router.resolve({ name: `Contract-File-ru-${this.Company.Type}` })
                    window.open(routeData.href, "_blank")
                    this.$router.push({ name: "Contract" })
                })
                .finally(() => this.switchLoading())
        },
        getByINN() {
            this.$refs.INN.validateSilent()
                .then((data) => {
                    if (data.valid) {
                        this.switchLoading()
                        this.$store.dispatch(INN_REQUEST, this.Company.Inn)
                            .then((response) => {
                                if (response && response.data) {
                                    this.Company.FullName = response.data[0].FullName
                                    this.Company.ShortName = response.data[0].ShortName
                                    this.Company.Registered = new Date(response.data[0].Registered)
                                    this.Company.Ogrn = response.data[0].Ogrn
                                    this.Company.Kpp = response.data[0].Kpp
                                    this.Company.Address = response.data[0].Address
                                    this.Company.ManagerPosition = response.data[0].ManagerPosition
                                    this.Company.ManagerName = response.data[0].ManagerName
                                }
                            })
                            .finally(() => this.switchLoading())
                    }
                })
        },
        getByBIC() {
            this.$refs.BIC.validateSilent()
                .then((data) => {
                    if (data.valid) {
                        this.switchLoading()
                        this.$store.dispatch(BIC_REQUEST, this.Company.Bic)
                            .then((response) => {
                                if (response && response.data && response.data.Name) {
                                    this.Company.BankName = response.data.Name
                                    this.Company.BankCity = response.data.City
                                    this.Company.CorrespondentBankAccount = response.data.CorrespondentAccount
                                }
                            })
                            .finally(() => this.switchLoading())
                    }
                })
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Contract.Create.Title")
        }
    }
}
</script>
